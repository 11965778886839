import React from 'react'

export const CatalystWarning = React.memo(function (props: React.SVGAttributes<SVGElement>) {
  return (
    <svg {...props} width="100" height="100" viewBox="0 0 100 100" fill="none">
      <circle opacity="0.5" cx="50" cy="50" r="50" fill="#43404A"/>
      <path d="M74.7586 65.0714L51.5457 24.8929C51.1997 24.2958 50.6026 24 50 24C49.3974 24 48.7947 24.2958 48.4543 24.8929L25.2414 65.0714C24.555 66.2656 25.4143 67.75 26.787 67.75H73.213C74.5857 67.75 75.445 66.2656 74.7586 65.0714ZM48.2144 40.5179C48.2144 40.2723 48.4153 40.0714 48.6608 40.0714H51.3392C51.5847 40.0714 51.7856 40.2723 51.7856 40.5179V50.7857C51.7856 51.0312 51.5847 51.2321 51.3392 51.2321H48.6608C48.4153 51.2321 48.2144 51.0312 48.2144 50.7857V40.5179ZM50 60.1607C49.2991 60.1464 48.6317 59.8579 48.1411 59.3571C47.6505 58.8564 47.3757 58.1832 47.3757 57.4821C47.3757 56.7811 47.6505 56.1079 48.1411 55.6071C48.6317 55.1064 49.2991 54.8179 50 54.8036C50.7009 54.8179 51.3683 55.1064 51.8589 55.6071C52.3495 56.1079 52.6243 56.7811 52.6243 57.4821C52.6243 58.1832 52.3495 58.8564 51.8589 59.3571C51.3683 59.8579 50.7009 60.1464 50 60.1607Z" fill="white"/>
    </svg>
  )
})
